import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Header } from "./Header";
import { SaveTheDate } from "./routes/SaveTheDate";
import { Home } from "./routes/Home";
import { Events } from "./routes/Events";
import { Travel } from "./routes/Travel";
import { RSVP, RSVPThankYou } from "./routes/RSVP";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useRoutes,
  useLocation,
} from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";
import { Clothes } from "./routes/Clothes";
import { Pickup } from "./routes/Pickup";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/save-the-date"
          element={
            <Page>
              <SaveTheDate />
            </Page>
          }
        />
        <Route
          path="/"
          element={
            <Page>
              <SaveTheDate />
            </Page>
          }
        />

        <Route
          path="/events"
          element={
            <Page>
              <Events />
            </Page>
          }
        />
        <Route
          path="/clothes"
          element={
            <Page>
              <Clothes />
            </Page>
          }
        />
        <Route
          path="/pickup"
          element={
            <Page>
              <Pickup />
            </Page>
          }
        />
        <Route
          path="/RSVP"
          element={
            <Page>
              <RSVP />
            </Page>
          }
        />
        <Route
          path="/RSVPThankYou"
          element={
            <Page>
              <RSVPThankYou willAttend="yes" />
            </Page>
          }
        />
        <Route
          path="/RSVPThanksButNoThanks"
          element={
            <Page>
              <RSVPThankYou willAttend="no" />
            </Page>
          }
        />

        <Route
          path="/travel"
          element={
            <Page>
              <Travel />
            </Page>
          }
        />
        {/* <Route path="/baby" /> */}
        <Route
          path="*"
          element={
            <Page>
              <div className="section">Uh oh! There's nothing here...</div>
              <Link to="/">Take me back</Link>
            </Page>
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function Page({ children }: { children: React.ReactNode }) {
  const hideNav = false;

  React.useEffect(() => {}, []); // on mount

  return (
    <div className={`App${hideNav ? " hide-nav" : ""}`}>
      <Header />
      <div className="content">
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </div>
  );
}
